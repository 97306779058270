<template src="./IframeMain.html"></template>

<script>
import ShowBuyByTIDaSID from "@/components/show/ShowBuyByTIDaSID";
import ShowBuyByTIDaEID from "@/components/show/ShowBuyByTIDaEID";

export default {
  name: "IframeMainTest",
  components:{ShowBuyByTIDaSID,ShowBuyByTIDaEID},
  data: () => ({

    mode: "global",
    id_pack:{
      tid:  2,
      sid:  43,
      eid:  296,
    },
    tid:  0,
    sid:  0,
    eid:  0,
    param: "",


    tab: null,
    items: [
      'Афиша', 'Спектакль', 'Места', 'Оплата', 'Билеты',
    ],
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    db_links:[[2,52],[2,272]],

    error: false,
    errorM: "no error",

  }),

  created() {

    this.selectMode();



  },

  computed:{


  },



  mounted() {

    console.log('mount');
  },



  methods:{

    selectMode(){

      console.log('selectt');

      //console.log(this.$route.params);

      switch (this.$route.params.mode){

        case "afisha":
          this.mode = "afisha";

          if (this.$route.params.tid != undefined){
            this.tid = this.$route.params.tid;
            //группирую по тому же принципу что и в последних компонентах
            this.id_pack.tid = this.$route.params.tid;
          }
          else{
            this.error = true;
            this.errorM = "no theater ID";
          }

          console.log(this.tid);

          // this.$store.dispatch("localAPILogin", {
          //   theater : this.tid
          // })
          //     .then(()=>{
          //
          //       console.log('auth API theater done');
          //
          //     })





          break;

        case "show":
          this.mode = "show";
          break;

        case "event":
          this.mode = "event";
          break;

        default:
          this.mode = "global";
          break;

      }


      if (this.$route.params.tid) {

        //this.theater = this.$route.params.tid;

      }
      else{
        console.log('нет театра нет входа')
        return '/none'
      }

    },
    goTo(data){
      this.id_pack.tid = data[0];
      switch (this.mode) {
        case "show":
          this.id_pack.sid = data[1];
        break;
        case "event":
          this.id_pack.eid = data[1];
        break;
      }
      // this.$router.push(link);
      // this.$router.go();

    },
  }
}



</script>

<style scoped>

.show{
  margin: 25px auto;
  padding: 10px;
  width:600px;
  background-color: #ffec85;
  border-radius: 5px;
  font-size:2rem;
  color:black;
  text-align: center;
  cursor: pointer;
}
.show:hover{

  background-color: #ffbb51;
}

a{
  color:black;
  text-decoration: none;
}
@media(min-width:768px){
  .col-sm-20.col{
    flex: 0 0 20%;
    max-width: 20%;
  }
}
/*add*/
.cardAfisha{
  border-radius: 6px;
  overflow: hidden;
}
.afishaImage{
  height: 360px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}
.afishaImage a{
  display: block;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
}
.afishaImage a img{
  height: auto;
  width: 100%;
  max-width: 100%;
  /*max-height: 100%;*/
  transition: 0.5s;
}
.afishaImage.shadowImage img{
  opacity: 0.5;
  transition: 0.5s;
}
.afishaImage.shadowImage .linkStoped{
  pointer-events: none;
}

.afishaImage a span{
  display: inline-block;
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%) scale(0);
  padding: 5px 10px;
  border-radius: 3px;
  background: #2196F3;
  color:#fff;
  transition:0.5s;
  box-shadow: 0 0 2px #000;
  font-weight: bold;
  opacity: 0;
}
.afishaImage a:hover span{
  transform:translate(-50%,-50%) scale(1);
  opacity: 1;
}
.afishaImage a span:hover{
  transform:translate(-50%,-50%) scale(1.1);
  opacity: 1;
  box-shadow: 0 0 5px #000;
  transition: 0.5s;
}
/*.afishaImage.shadowImage a span{*/
/*  transform:translate(-50%,-50%) scale(0) !important;*/
/*  opacity: 0 !important;*/
/*}*/
.afishaImage.shadowImage:before{
  content: '';
  display: block;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 1;
}
.afishaImage a:hover img{
  transition:0.5s;
  transform:scale(1.08);
}
</style>
